// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-credit-card-fraud-detection-js": () => import("./../../../src/pages/credit-card-fraud-detection.js" /* webpackChunkName: "component---src-pages-credit-card-fraud-detection-js" */),
  "component---src-pages-diaper-features-impact-js": () => import("./../../../src/pages/diaper-features-impact.js" /* webpackChunkName: "component---src-pages-diaper-features-impact-js" */),
  "component---src-pages-impressions-prediction-social-media-js": () => import("./../../../src/pages/impressions-prediction-social-media.js" /* webpackChunkName: "component---src-pages-impressions-prediction-social-media-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-twitter-analysis-us-senators-js": () => import("./../../../src/pages/twitter-analysis-us-senators.js" /* webpackChunkName: "component---src-pages-twitter-analysis-us-senators-js" */)
}

